import React from 'react';
import ministryContentData from './ministryContentData.json';
import DOMPurify from 'dompurify';


const OutReach = () => {
  // Assuming that the import and the JSON data is now being correctly handled
  const outreachContent = ministryContentData['outreach'];

  // Sanitize the title and description for each card
  const sanitizedCards = outreachContent.cards.map(card => ({
      ...card,
      title: DOMPurify.sanitize(card.title),
      description: DOMPurify.sanitize(card.description)
  }));

  return (
      <div className="content-container">
          <h3>{outreachContent.title}</h3>
          <div className="card-container">
              {sanitizedCards.map((card, index) => (
                  <div key={index} className="card" style={{ backgroundImage: `url(${card.image})` }}>
                      <div className="card-overlay">
                          <div className="card-title" dangerouslySetInnerHTML={{ __html: card.title }} />
                          <div className="card-description" dangerouslySetInnerHTML={{ __html: card.description }} />
                      </div>
                  </div>
              ))}
          </div>
      </div>
  );
};

export default OutReach;
