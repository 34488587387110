import React from 'react';
import ministryContentData from './ministryContentData.json';
import DOMPurify from 'dompurify';

const MusicSchool = () => {
  const cleanHtml = DOMPurify.sanitize(ministryContentData['music-school'].body);

  return (
    <div className="content-container">
      <h3>{ministryContentData['music-school'].title}</h3>
      <br></br>
      <div dangerouslySetInnerHTML={{ __html: cleanHtml }} style={{marginRight: '96px'}}/>
    </div>
  );
};

export default MusicSchool;
