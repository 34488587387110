import React, { useRef, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import Masonry from 'react-masonry-css';
import LazyLoad from 'react-lazyload';
import 'react-image-lightbox/style.css'; // Lightbox CSS
import './Gallery.css'; // Your CSS for custom styles

const Gallery = () => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
  // Define your breakpoint columns object for the Masonry layout
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1
  };

  // Placeholder array for gallery images
  const images = [
    '/gallery_files/06.jpg', // Wide
    '/gallery_files/05.jpg', // Tall
    '/gallery_files/01.jpg', // Medium
    '/gallery_files/08.jpg', // Wide but smaller
    '/gallery_files/13.jpg', // Very Tall
    '/gallery_files/02.jpg', // Medium
    '/gallery_files/09.jpg', // Small
    '/gallery_files/14.jpg', // Tall and narrow
    '/gallery_files/23.jpg', // Medium
    '/gallery_files/12.jpg', // Wide
    '/gallery_files/04.jpg', // Very Tall and narrow
    '/gallery_files/21.jpg', // Medium
    '/gallery_files/10.jpg', // Tall and narrow
    '/gallery_files/24.jpg', // Medium but square
    '/gallery_files/15.jpg', // Small
    '/gallery_files/07.jpg', // Medium
    '/gallery_files/26.jpg', // Tall and narrow
    '/gallery_files/16.jpg', // Small
    '/gallery_files/22.jpg', // Medium
    '/gallery_files/17.jpg', // Small
    '/gallery_files/25.jpg', // Medium
    '/gallery_files/18.jpg', // Small
    '/gallery_files/27.jpg', // Medium
    '/gallery_files/19.jpg', // Small
    '/gallery_files/20.jpg', // Small
  ];

  const backgroundImageRef = useRef(null);
  const [isImageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setImageLoaded(true);
            }
        });
    }, {
        threshold: 0.1 // Adjust this value based on your needs
    });

    if (backgroundImageRef.current) {
        observer.observe(backgroundImageRef.current);
    }

    return () => {
        if (backgroundImageRef.current) {
            observer.unobserve(backgroundImageRef.current);
        }
    };
  }, []);

  return (
    <Container fluid>
      {/* Banner */}
      <Row>
        <Col md={12} className='g-0'>
          <LazyLoad height={200} offset={100} once>
            <div className="background-container">
              <div className="overlay" />
              <h2 className="header-title">
                Gallery
              </h2>
            </div>
          </LazyLoad>
        </Col>
      </Row>

    {/* Gallery */}
    <div className="gallery-container">
    <Masonry
    breakpointCols={breakpointColumnsObj}
    className="my-masonry-grid"
    columnClassName="my-masonry-grid_column"
    >
    {images.map((image, index) => (
        <div key={index} className="gallery-item">
            <LazyLoad height={200} offset={100} once placeholder={<div>Loading...</div>}>
              <img
                src={image}
                alt={`Gallery item ${index + 1}`}
                onClick={() => {
                  setPhotoIndex(index);
                  setIsOpen(true);
                }}
              />
            </LazyLoad>
        </div>
    ))}
    </Masonry>

    {isOpen && (
        <Lightbox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
    )}
    </div>
    </Container>
  );
}

export default Gallery;
