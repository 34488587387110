import React from 'react';
import ministryContentData from './ministryContentData.json';
import DOMPurify from 'dompurify';

const Youth = () => {
  const cleanHtml = DOMPurify.sanitize(ministryContentData['youth'].body);

  return (
    <div className="content-container">
      <h3>{ministryContentData['youth'].title}</h3>
      <br></br>
      <div dangerouslySetInnerHTML={{ __html: cleanHtml }} style={{marginRight: '96px'}}/>
    </div>
  );
};

export default Youth;
