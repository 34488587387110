import React, { useState, useEffect } from 'react';
import './SermonHighlights.css';

const SermonHighlights = ({ apiKey, channelId }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      const apiUrl = `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=relevance&type=video&maxResults=4`;

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setVideos(data.items);
      } catch (error) {
        setError('Failed to fetch videos. Please try again later.');
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleVideoClick = (videoId) => {
    window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank');
  };

  return (
    <div className="sermon-highlights">
        <h2>Sermon Highlights</h2>
        <div className="video-grid">
        {videos.map((video) => (
            <div key={video.id.videoId} className="video-item">
            <div className="video-overlay" onClick={() => handleVideoClick(video.id.videoId)}>
                <h3>{video.snippet.title}</h3>
                <p>{new Date(video.snippet.publishedAt).toLocaleDateString()}</p>
            </div>
            <div className="iframe-wrapper">
                <iframe
                title="video"
                src={`https://www.youtube.com/embed/${video.id.videoId}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                ></iframe>
            </div>
            </div>
        ))}
        </div>
        <div className="more-button-container">
          <a className='more-button' href="/sermons" target="_blank" rel="noopener noreferrer">MORE</a>
        </div>
    </div>
  );

};

export default SermonHighlights;
