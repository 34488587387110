import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './MinistrySnippet.css';

const MinistrySnippet = () => {
  return (
    <Container fluid className="ministry-snippet-container">
      <Row className="ministry-snippet-container-row">
        <Col md={6} className="ministry-image-container">
          {/* Image is set via CSS */}
        </Col>
        <Col md={6} className="ministry-content-container">
          <div className="ministry-header">
            <h2>Our Ministry</h2>
          </div>
          <div className="ministry-paragraph">
            <p>
              Grace Community Baptist Church extends support to Evangelists, Pastors, and Missionaries, focusing on rural areas. Regular pastors' conferences and outreach to villages aim for financial aid, edification, and encouragement. Our mission centres on unity with Christ, the 'Great Commission,' and sharing resources for God's glory through various ministries.
            </p>
          </div>
          <div className="ministry-button">
            <a className='btn-custom' href="/ministry" target="_blank" rel="noopener noreferrer">READ MORE</a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MinistrySnippet;
