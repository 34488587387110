import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import './NavBar.css';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';

function NavBar() {
    const [scrolled, setScrolled] = useState(false);
    const [expanded, setExpanded] = useState(false); 

    useEffect(() => {
    
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 200) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
    
        // Attach the event listener directly to the window object
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Navbar expand="lg" 
                fixed="top" 
                expanded={expanded}
                onToggle={() => setExpanded(!expanded)}
                className={`mynavbar ${scrolled ? "navbar-scrolled" : ""} ${expanded ? "expanded" : ""}`}
                style={{alignItems: 'center', zIndex: 999}}> 
            <Container>
                <LinkContainer to="/">
                    <Navbar.Brand style={{alignItems: 'center'}}>
                        <img src="/gcbc_logo.svg" alt="GCBC Logo" className="logo" style={{ width: '136px', height: '136px' }} />
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="custom-toggler" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <NavDropdown title="ABOUT" id="about-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/about#about-gcbc" onClick={() => setExpanded(false)}>ABOUT GCBC</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/about#the-gospel" onClick={() => setExpanded(false)}>THE GOSPEL</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/about#our-history" onClick={() => setExpanded(false)}>HISTORY</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/about#meet-the-pastor" onClick={() => setExpanded(false)}>MEET THE PASTOR</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/about#statement-of-faith" onClick={() => setExpanded(false)}>STATEMENT OF FAITH</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="MINISTRIES" id="ministries-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/ministry#our-ministry" onClick={() => setExpanded(false)}>OUR MINISTRY</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/ministry#youth" onClick={() => setExpanded(false)}>YOUTH</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/ministry#children" onClick={() => setExpanded(false)}>CHILDREN</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/ministry#outreach" onClick={() => setExpanded(false)}>OUTREACH</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/ministry#music-school" onClick={() => setExpanded(false)}>MUSIC SCHOOL</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/ministry#giving" onClick={() => setExpanded(false)}>GIVING</NavDropdown.Item>
                        </NavDropdown>
                        <LinkContainer to="/sermons">
                            <Nav.Link onClick={() => setExpanded(false)}>SERMONS</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/gallery-view">
                            <Nav.Link onClick={() => setExpanded(false)}>GALLERY</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/contact">
                            <Nav.Link onClick={() => setExpanded(false)}>CONTACT</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
