import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import './Ministry.css';

import OurMinistry from './ministry_content_components/OurMinistry';
import Youth from './ministry_content_components/Youth';
import ChildrenMin from './ministry_content_components/ChildrenMin';
import OutReach from './ministry_content_components/OutReach';
import MusicSchool from './ministry_content_components/MusicSchool';
import Giving from './ministry_content_components/Giving';

const Ministry = () => {
    const [activeSection, setActiveSection] = useState('our-ministry');
    const location = useLocation();

    // Define the mapping of section keys to their content components
    const sectionContentComponents = {
        'our-ministry': OurMinistry,
        'youth': Youth,
        'children': ChildrenMin,
        'outreach': OutReach,
        'music-school': MusicSchool,
        'giving': Giving
    };

    const sectionBanners = {
        'our-ministry': '/ministry_banners/1.jpg',
        'youth': '/ministry_banners/2.jpg',
        'children': '/ministry_banners/3.jpg',
        'outreach': '/ministry_banners/4.jpg',
        'music-school': '/ministry_banners/5.jpg',
        'giving': '/ministry_banners/6.jpg'
    };

    useEffect(() => {
        const currenHash = location.hash.replace('#', '');
        setActiveSection(currenHash || 'our-ministry');
    }, [location.hash]);

    // Function to render content dynamically based on the active section
    const renderContent = () => {
        const ContentComponent = sectionContentComponents[activeSection];
        return ContentComponent ? <ContentComponent /> : null;
    };
    // Function to determine if a ListGroup.Item should be highlighted
    const isActive = (section) => activeSection === section;

    const getBannerImage = () => sectionBanners[activeSection];

    return (
        <Container fluid>
        <Row nogutters>
            <Col md={12} style={{ padding: 0 }}>
              <LazyLoad height={593} offset={100} once>
                  <img 
                      src={getBannerImage()} 
                      alt="Banner"
                      style={{
                          width: '100vw',
                          height: '593px',
                          objectFit: 'cover'
                      }}
                  />
              </LazyLoad>
            </Col>
        </Row>
        <Row nogutters>
          <Col md={3} style={{ padding: 0 }} className="sidebar-menu">
            <ListGroup>
              {Object.keys(sectionContentComponents).map((section) => (
                <ListGroup.Item
                  key={section}
                  action
                  onClick={() => setActiveSection(section)}
                  active={isActive(section)}
                  className="list-item"
                >
                  <p>{section.replace(/-/g, ' ').toUpperCase()}</p>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={9} style={{ paddingTop: '20px' }}>
            {renderContent()}
          </Col>
        </Row>
      </Container>
    );
};

export default Ministry;

