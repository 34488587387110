import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import Masonry from 'react-masonry-css';
import 'react-image-lightbox/style.css'; 
import './GalleryHighlight.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import LazyLoad from 'react-lazyload';

const images = [
  '/gallery_files/01.jpg',
  '/gallery_files/02.jpg',
  '/gallery_files/04.jpg',
  '/gallery_files/05.jpg',
  '/gallery_files/06.jpg',
];

const breakpointColumnsObj = {
  default: 2,
  992: 2,
  768: 2,
  576: 1
};

const GalleryHighlight = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container className="gallery-container">
      <h2>Our Gallery</h2>
      <p>Explore the heart of our church, one image at a time, in our captivating gallery.</p>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image, index) => (
          <LazyLoad key={image} height={200} offset={100} once>
            <img
              key={image}
              src={image}
              className="image-thumbnail"
              onClick={() => {
                setPhotoIndex(index);
                setIsOpen(true);
              }}
              alt={`Gallery image ${index + 1}`}
            />
          </LazyLoad>
        ))}
      </Masonry>
      <div className="icon-container">
        <a className="gallery-icon-link" href="/gallery-view" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faCirclePlus} size="3x" />
        </a>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          imageCaption={`Image ${photoIndex + 1} of ${images.length}`}
          enableZoom={true}
        />
      )}
    </Container>
  );
};

export default GalleryHighlight;
