import React from 'react';
import ministryContentData from './ministryContentData.json';
import DOMPurify from 'dompurify';

const Giving = () => {
  const cleanHtml = DOMPurify.sanitize(ministryContentData['giving'].body);
  const cardData = ministryContentData['giving'].card[0];
  const cleanTitle = cardData.title;
  const cleanDescription = cardData.description;

  return (
    <div className="content-container">
      <h3>{ministryContentData['giving'].title}</h3>
      <div className="giving-card-container" style={{marginRight: '96px'}}>
        <img 
        src={cardData.svg}
        alt='Donate'
        style={{width: '62px', height: '48px', marginBottom:'20px'}}/>
        <div dangerouslySetInnerHTML={{ __html: cleanTitle }} />
        <div dangerouslySetInnerHTML={{ __html: cleanDescription }} />
      </div>
      <div dangerouslySetInnerHTML={{ __html: cleanHtml }} style={{marginRight: '96px'}}/>
    </div>
  );
};

export default Giving;