import React from 'react';
import aboutContentData from './aboutContentData.json';
import DOMPurify from 'dompurify';

const OurHistory = () => {
  const cleanHtml = DOMPurify.sanitize(aboutContentData['our-history'].body);

  return (
    <div className="about-history-content">
      <h3>{aboutContentData['our-history'].title}</h3>
      <br></br>
      <div dangerouslySetInnerHTML={{ __html: cleanHtml }} style={{marginRight: '96px'}}/>
    </div>
  );
};

export default OurHistory;
