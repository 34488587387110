import React from 'react';
import './QuickLinks.css'; 

const QuickLinks = () => {
  return (
    <div className="quick-links-container">
        <div className="quick-links-column">
            <h2 className="quick-links-title">NEW HERE?</h2>
            <p className="quick-links-paragraph">Explore our church family and what we believe.</p>
            <a className='quick-links-button' href="/about" target="_blank" rel="noopener noreferrer">GET STARTED</a>
        </div>
        <div className="quick-links-column">
            <h2 className="quick-links-title">SUNDAY'S ONLINE</h2>
            <p className="quick-links-paragraph">Be part of our Sunday service - wherever you are.</p>
            <a className='quick-links-button' href="https://www.youtube.com/@gracecommunitybaptistchurch95" target="_blank">JOIN NOW</a>
        </div>
        <div className="quick-links-column">
            <h2 className="quick-links-title">GIVING</h2>
            <p className="quick-links-paragraph">Support our ministry through your contributions.</p>
            <a className='quick-links-button' href="/ministry#giving" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
        </div>
    </div>
  );
};

export default QuickLinks;
