import React from 'react';
import aboutContentData from './aboutContentData.json'; 
import DOMPurify from 'dompurify';

const MeetPastor = () => {
  const { title, image } = aboutContentData['meet-the-pastor'];
  const cleanHtml = DOMPurify.sanitize(aboutContentData['meet-the-pastor'].body);

  return (
    <div className="meet-pastor-container" style={{ margin: '0 auto' }}>
      <h3>{title}</h3>
      <br></br>
      <div>
        <img 
          src={image} 
          alt="Rev. Emmanuel Damara" 
          style={{ width: '300px', height: '450px', objectFit: 'contain', float: 'left', marginRight: '20px' }} 
        />
        <div className="pastor-text">
          <div dangerouslySetInnerHTML={{ __html: cleanHtml }} style={{marginRight: '96px'}}/>
        </div>
      </div>
    </div>
  );
}

export default MeetPastor;
