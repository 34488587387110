import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-between">
          <Col md={3} className="footer-col">
            <h5>Location</h5>
            <p>199, Adrin Cross Roads, Secunderbad - 500009, Telangana, INDIA</p>
          </Col>
          <Col md={3} className="footer-col contact-info">
            <h5>Contact</h5>
            <div className="contact-item">
                <FontAwesomeIcon icon={faPhone} className="contact-icon" style={{ fontSize: '1rem', width: '30px', textAlign: 'center'}} />
                <span className="contact-text">+91-40-27720979</span>
            </div>
            <div className="contact-item">
                <FontAwesomeIcon icon={faMobileAlt} className="contact-icon" style={{ fontSize: '1rem', width: '30px', textAlign: 'center'}} />
                <span className="contact-text">+91-9849073444</span>
            </div>
            <div className="contact-item">
                <FontAwesomeIcon icon={faEnvelope} className="contact-icon" style={{ fontSize: '1rem', width: '30px', textAlign: 'center'}} />
                <span className="contact-text">dr.emmanuel.damara@gmail.com</span>
            </div>
          </Col>
          <Col md={3} className="footer-col">
            <h5>Useful Links</h5>
            <ul>
              <li><Link className='useful-link' to="/">Home</Link></li>
              <li><Link className='useful-link' to="/about#about-gcbc">About</Link></li>
              <li><Link className='useful-link' to="/ministry#our-ministry">Ministries</Link></li>
              <li><Link className='useful-link' to="/sermons">Sermons</Link></li>
              <li><Link className='useful-link' to="/gallery-view">Gallery</Link></li>
              <li><Link className='useful-link' to="/contact">Contact</Link></li>
            </ul>
          </Col>
          <Col md={3} className="footer-col text-center">
            <Image src="/gcbc_logo.svg" alt="Logo" style={{ width: '187px', height: '187px' }} />
            <div className="social-icons">
              <a href="https://www.facebook.com/pages/Grace-Community-Baptist-Church/340536912675081?eid=ARCsVccPWwqIeyDfWLui3HhGX_Q8LG3iifs0VthkamGdwowcGEQ9OH1Y0sE4gJqCFH6_Emels_GGeP5K&hc_ref=ARQ6AQ_M-q724W19k3vaQ4M6PZRjsoce5WC0uYsSLkIJLBqCO15Sx9HmikTkbBjsl4U&ref=nf_target&fref=tag&__xts__[0]=68.ARBb8VFNFAi2NadBd2yj13V-i-lr1iMSkIgdp8qH33q0jsvE_z9IVdyeiO7aenInJuIbROToebWODK6zzuYt8z4UGnICALGOTcF-OXm4LazR1O8kLmH_qk29FaXPvKXgBiAG50ohs4JYXGgSj2pakp9-GHIIAwPGUS-1GZkGpOim3ew54n46lRQr_ULFzFf2S2hAbaGDVoUizj718GeWkZ5uUAI8CmyXlvkJJJ4koCDFPSC9Dh8mxPE0c0VEy-g_AL9rULE0D3vv2UwXOtyyMwWDxltXPtY5-OcmrK7w3YM4n-YvSFOJ-2UZWkT-ZXuv7wG732RT39F3CZoEo6tbPhe20x_ypjK9YSdh30xB3MH-y2MZNoanVxPHSarRwtIj7BpTI2rIvakfmCb1G5fHgx60DCghPozWWQtsOCwv0G5Ovs0xzEl2xVOzxWMk" target='_blank' className="social-icon">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="https://www.youtube.com/channel/UC1X_OuSC-mmM18jMA8HG96w" target='_blank' className="social-icon">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a href="https://www.instagram.com/gcbc1995/" target='_blank' className="social-icon">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="footer-bottom-col">
            <p className="text-center">&copy; 2024 Grace Community Baptist Church All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
