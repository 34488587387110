import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import './CustomCarousel.css'

function CustomCarousel({data}) {

  const slideContent = [
    {
        title: "SUNDAY SERVICE",
        time: "9:00 AM to 11:00 AM",
        description: "Join us this Sunday for worship services and fellowship.",
        buttonText: "MORE"
    },
    {
        title: "WEDNESDAY PRAYER",
        time: "7:00 PM",
        description: "Join us in prayer, where faith unites and blessings abound.",
        buttonText: "MORE"
    },
    {
        title: "SATURDAY PRAYER",
        time: "8:00 AM",
        description: "Awaken your spirit every Saturday. Join us in fervent prayer.",
        buttonText: "MORE"
    }
  ];

  return (
      <Carousel 
      prevIcon={<span className="material-icons" >chevron_left</span>}
      nextIcon={<span className="material-icons">chevron_right</span>}
      className='custom-carousel'>
        {data.map((item, index) => {
          const content = slideContent[index];
          return (
            <Carousel.Item key={index} className={`slide ${index === 0 ? 'active' : ''}`}>
              <img src={item.src} alt={item.alt} className="d-block w-100" />
              <Carousel.Caption className="slide-content">
                <h1>{content.title}</h1>
                <h4>{content.time}</h4>
                <p>{content.description}</p>
                <a className='carousel-button' href="/contact" target="_blank" rel="noopener noreferrer">{content.buttonText}</a>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
  );
}

export default CustomCarousel