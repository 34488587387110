import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './About.css';
import LazyLoad from 'react-lazyload';

import AboutGCBC from './about_content_components/AboutGCBC';
import TheGospel from './about_content_components/TheGospel';
import OurHistory from './about_content_components/OurHistory';
import MeetPastor from './about_content_components/MeetPastor';
import Doctrine from './about_content_components/Doctrine';

const About = () => {
    const [activeSection, setActiveSection] = useState('about-gcbc');
    const location = useLocation();

    // Define the mapping of section keys to their content components
    const sectionContentComponents = {
        'about-gcbc': AboutGCBC,
        'the-gospel': TheGospel,
        'our-history': OurHistory,
        'meet-the-pastor': MeetPastor,
        'statement-of-faith': Doctrine
    };

    const sectionBanners = {
        'about-gcbc': '/about_banners/1.jpg',
        'the-gospel': '/about_banners/2.jpg',
        'our-history': '/about_banners/3.jpg',
        'meet-the-pastor': '/about_banners/4.jpg',
        'statement-of-faith': '/about_banners/5.jpg'
    };

    useEffect(() => {
        const currenHash = location.hash.replace('#', '');
        setActiveSection(currenHash || 'about-gcbc');
    }, [location.hash]);

    // Function to render content dynamically based on the active section
    const renderContent = () => {
        const ContentComponent = sectionContentComponents[activeSection];
        return ContentComponent ? <ContentComponent /> : null;
    };
    // Function to determine if a ListGroup.Item should be highlighted
    const isActive = (section) => activeSection === section;

    const getBannerImage = () => sectionBanners[activeSection];

    return (
        <Container fluid>
        <Row nogutters>
            <Col md={12} style={{ padding: 0 }}>
            <LazyLoad height={593} offset={100} once>
                  <img 
                      src={getBannerImage()} 
                      alt="Banner"
                      style={{
                          width: '100vw',
                          height: '593px',
                          objectFit: 'cover'
                      }}
                  />
              </LazyLoad>
            </Col>
        </Row>
        <Row nogutters>
          <Col md={3} style={{ padding: 0 }} className="sidebar-menu">
            <ListGroup>
              {Object.keys(sectionContentComponents).map((section) => (
                <ListGroup.Item
                  key={section}
                  action
                  onClick={() => setActiveSection(section)}
                  active={isActive(section)}
                  className="list-item"
                >
                  <p>{section.replace(/-/g, ' ').toUpperCase()}</p>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={9} style={{ paddingTop: '20px' }}>
            {renderContent()}
          </Col>
        </Row>
      </Container>
    );
};

export default About;

