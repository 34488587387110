import React from 'react';
import aboutContentData from './aboutContentData.json';
import DOMPurify from 'dompurify';

const AboutGCBCContent = () => {
  const cleanHtml = DOMPurify.sanitize(aboutContentData['about-gcbc'].body);

  return (
    <div className="about-gcbc-content">
      <h3>{aboutContentData['about-gcbc'].title}</h3>
      <br></br>
      <div dangerouslySetInnerHTML={{ __html: cleanHtml }} style={{marginRight: '96px'}}/>
    </div>
  );
};

export default AboutGCBCContent;
