import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import aboutContentData from './aboutContentData.json'; 
import DOMPurify from 'dompurify';

const Doctrine = () => {
  const statements = aboutContentData['statement-of-faith'].statements;

  return (
    <div className="doctrine-content">
      <h3>{aboutContentData['statement-of-faith'].title}</h3>
      <br></br>
      <p style={{marginRight: '96px'}}>{aboutContentData['statement-of-faith'].body}</p>
      <Accordion defaultActiveKey="0" className="doctrinal-statements" style={{marginRight: '96px'}}>
        {statements.map((statement, index) => (
          <Accordion.Item eventKey={String(index)} key={index}>
            <Accordion.Header>
              <div className="div-header" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(statement.title) }} />
            </Accordion.Header>
            <Accordion.Body>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(statement.body) }} />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default Doctrine;
