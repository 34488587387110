import React, { useEffect } from 'react';
import './App.css';
import NavBar from './components/NavBar';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import CustomCarousel from './components/CustomCarousel';
import QuickLinks from './components/QuickLinks';
import SermonHighlights from './components/SermonHighlights';
import MinistrySnippet from './components/MinistrySnippet';
import GalleryHighlight from './components/GalleryHighlight';
import HeroSection from './components/HeroSection';
import About from './components/About';
import Footer from './components/Footer';
import { slides } from './data/carouselData.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import Ministry from './components/Ministry';
import SermonVideos from './components/SermonVideos';
import Contact from './components/Contact';
import Gallery from './components/Gallery';


const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null; 
};


function App() {
  const apiKey = 'AIzaSyAlO-ooHNRKQbbz71qRsJk7LUiXhIWecQc';
  const channelId = 'UC1X_OuSC-mmM18jMA8HG96w';

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div id="root">
        <NavBar />
        <div className="app-content">
          <Routes>
            <Route path="/" element={
              <>
                <CustomCarousel data={slides}/>
                <QuickLinks />
                <MinistrySnippet />
                <SermonHighlights apiKey={apiKey} channelId={channelId}/>
                <HeroSection />
                <GalleryHighlight />
              </>
            } />
            <Route path="/about" element={<About />} />
            <Route path="/ministry" element={<Ministry />} />
            <Route path="/sermons" element={<SermonVideos apiKey={apiKey} channelId={channelId} />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gallery-view" element={<Gallery />}/>
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
