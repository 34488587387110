import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal, InputGroup, FormControl, Spinner, Alert, Pagination, Dropdown, DropdownButton } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import './SermonVideos.css';

// Helper function to fetch data from the YouTube API
const fetchYouTubeVideos = async (apiKey, channelId, pageToken = '', searchTerm = '', filter = 'date') => {
    let URL = `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=${filter}&maxResults=6&type=video&pageToken=${pageToken}`;
  if (searchTerm) {
    URL += `&q=${encodeURIComponent(searchTerm)}`;
  }
  if (pageToken) {
    URL += `&pageToken=${pageToken}`;
  }
  const response = await fetch(URL);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error.message);
  }
  return data;
};

// Video Card Component
const VideoCard = ({ video, handleVideoSelect }) => (
  <Col md={6} lg={4} className="mb-3">
    <Card onClick={() => handleVideoSelect(video)} className="card-override">
      <Card.Img variant="top" src={video.snippet.thumbnails.medium.url} />
      <Card.Body className="card-body-container">
        <h5>{video.snippet.title}</h5>
      </Card.Body>
    </Card>
  </Col>
);

// Main Component
const SermonVideos = ({ apiKey, channelId }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [nextPageToken, setNextPageToken] = useState('');
  const [prevPageToken, setPrevPageToken] = useState('');
  const [filter, setFilter] = useState('date');

  useEffect(() => {
    fetchVideos();
  }, []); // Empty dependency array means this effect will only run once on mount

  const fetchVideos = async (pageToken = '') => {
    setLoading(true);
    try {
      const data = await fetchYouTubeVideos(apiKey, channelId, pageToken, searchTerm, filter);
      setVideos(data.items);
      setNextPageToken(data.nextPageToken || '');
      setPrevPageToken(data.prevPageToken || '');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (eventKey) => {
    setFilter(eventKey);
    fetchVideos(); // Fetch videos again with the new filter
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchVideos(); // Resets to the first page of results
  };

  const handlePageChange = (token) => {
    fetchVideos(token);
  };

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
  };

  const handleClose = () => setSelectedVideo(null);

  if (loading) {
    return (
      <div style={{
        position: 'fixed', // Fixed position to cover the entire screen
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        backgroundColor: 'rgba(0, 0, 0, 0.3)', // Optional: Screen dimming
      }}>
        <Spinner animation="border" variant="light" />
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Container fluid>
    <Row>
    <Col md={12}>
      <LazyLoad height={200} offset={100} once>
        <div className="header-section">
          <div className="overlay" />
          <h2 className="header-title">
            Sermons
          </h2>
        </div>
      </LazyLoad>
    </Col>
    </Row>
    <Row>
    <Col md={11}>
        <InputGroup className="mb-3">
        <FormControl
            placeholder="Search sermons..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch(e)}
        />
        <Button variant="outline-secondary" onClick={handleSearch}>Search</Button>
        </InputGroup>
    </Col>
    <Col md={1}>
        <DropdownButton
        id="dropdown-filter-button"
        title="Filter"
        onSelect={handleFilterChange}
        className="mb-3"
        >
        <Dropdown.Item eventKey="date">Most Recent</Dropdown.Item>
        <Dropdown.Item eventKey="viewCount">Most Viewed</Dropdown.Item>
        <Dropdown.Item eventKey="rating">Top Rated</Dropdown.Item>
        </DropdownButton>
    </Col>
    <Row>
      {videos.map((video) => (
        <VideoCard key={video.id.videoId} video={video} handleVideoSelect={handleVideoSelect} />
      ))}
    </Row>
    <Pagination className="justify-content-center my-4">
        {prevPageToken && <Pagination.Prev onClick={() => handlePageChange(prevPageToken)} />}
        {nextPageToken && <Pagination.Next onClick={() => handlePageChange(nextPageToken)} />}
    </Pagination>
    </Row>
    {selectedVideo && (
    <Modal 
        show={!!selectedVideo} 
        onHide={handleClose}
        size="lg"
        dialogClassName="modal-block"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            <h4>{selectedVideo.snippet.title}</h4>
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <iframe
            style={{ width: '100%', height: '400px', border: 'none'}}
            src={`https://www.youtube.com/embed/${selectedVideo.id.videoId}`}
            title="Video Player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
        </Modal.Body>
    </Modal>
    )}
    </Container>
  );
};

export default SermonVideos;

