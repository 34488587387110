import React, { useRef, useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMobileAlt, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import emailjs from '@emailjs/browser';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'libphonenumber-js';
import LazyLoad from 'react-lazyload';
import 'react-phone-number-input/style.css';
import './Contact.css'; // Assuming you have a CSS file for styles

const Contact = () => {
    
    const formik = useFormik({
        initialValues: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          messageType: '',
          message: '',
        },
        validationSchema: Yup.object({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          email: Yup.string().email('Invalid email address').required('Email is required'),
          phone: Yup.string()
          .required('Phone number is required')
          .test(
            'isValid',
            'Phone number is not valid',
            value => value ? isValidPhoneNumber(value) : false
          ),
          messageType: Yup.string().required('Message type is required'),
          message: Yup.string().required('Please fill the message field'),
        }),
        onSubmit: (values) => {
          // Function to send email
          emailjs.send('service_8ocjd28', 'template_6zs6vba', values, 'MzL3otnfbazw83g-m')
            .then((response) => {
              console.log('SUCCESS!', response.status, response.text);
              alert('Your message has been sent!');
            }, (err) => {
              console.log('FAILED...', err);
              alert('Failed to send the message, please try again.');
            });
        },
      });

      const backgroundImageRef = useRef(null);
      const [isImageLoaded, setImageLoaded] = useState(false);

      useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setImageLoaded(true);
                }
            });
        }, {
            threshold: 0.1 // Adjust this value based on your needs
        });

        if (backgroundImageRef.current) {
            observer.observe(backgroundImageRef.current);
        }

        return () => {
            if (backgroundImageRef.current) {
                observer.unobserve(backgroundImageRef.current);
            }
        };
    }, []);

  return (
    <div>
        <Row className='g-0'>
            <Col md={12} className='g-0'>
                <LazyLoad height={200} offset={100} once>
                    <div className="background-image">
                        <div className="overlay" />
                        <h2 className="header-title">
                            Contact
                        </h2>
                    </div>
                </LazyLoad>
            </Col>
        </Row>
        <div className="contact-form-container">
            <Row className='g-0'>
                <Col md={6} className="contact-details-container">
                    <h2>Contact Information</h2>
                    <p>Feel free to fill the form if you have any queries. If you have a prayer request, you can share it with us using the same.</p>

                    <div className="info">
                        <FontAwesomeIcon icon={faPhone} className="icon" />
                        <span>+91-40-27720979</span>
                    </div>
                    <div className="info">
                        <FontAwesomeIcon icon={faMobileAlt} className="icon" />
                        <span>+91-9849073444</span>     
                    </div>
                    <div className="info">
                        <FontAwesomeIcon icon={faEnvelope} className="icon" />
                        <span>dr.emmanuel.damara@gmail.com</span>
                    </div>
                    <div className="info">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                        <span>199, Adrin Cross Roads, Secunderbad - 500009, Telangana, INDIA</span>
                    </div>

                    <div className="social-icons-contact">
                        <a href="https://www.facebook.com/pages/Grace-Community-Baptist-Church/340536912675081?eid=ARCsVccPWwqIeyDfWLui3HhGX_Q8LG3iifs0VthkamGdwowcGEQ9OH1Y0sE4gJqCFH6_Emels_GGeP5K&hc_ref=ARQ6AQ_M-q724W19k3vaQ4M6PZRjsoce5WC0uYsSLkIJLBqCO15Sx9HmikTkbBjsl4U&ref=nf_target&fref=tag&__xts__[0]=68.ARBb8VFNFAi2NadBd2yj13V-i-lr1iMSkIgdp8qH33q0jsvE_z9IVdyeiO7aenInJuIbROToebWODK6zzuYt8z4UGnICALGOTcF-OXm4LazR1O8kLmH_qk29FaXPvKXgBiAG50ohs4JYXGgSj2pakp9-GHIIAwPGUS-1GZkGpOim3ew54n46lRQr_ULFzFf2S2hAbaGDVoUizj718GeWkZ5uUAI8CmyXlvkJJJ4koCDFPSC9Dh8mxPE0c0VEy-g_AL9rULE0D3vv2UwXOtyyMwWDxltXPtY5-OcmrK7w3YM4n-YvSFOJ-2UZWkT-ZXuv7wG732RT39F3CZoEo6tbPhe20x_ypjK9YSdh30xB3MH-y2MZNoanVxPHSarRwtIj7BpTI2rIvakfmCb1G5fHgx60DCghPozWWQtsOCwv0G5Ovs0xzEl2xVOzxWMk" target='_blank' className="social-icon">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a href="https://www.youtube.com/channel/UC1X_OuSC-mmM18jMA8HG96w" target='_blank' className="social-icon">
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>
                        <a href="https://www.instagram.com/gcbc1995/" target='_blank' className="social-icon">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                </Col>

                <Col md={6}>
                    <Form className="contact-form" noValidate onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col sm={6}>
                                <Form.Group controlId="formFirstName">
                                <p style={{display: 'inline-flex', margin: '0px'}} className='field-text'>First Name<p style={{color: 'red', marginLeft: '2px'}}> *</p></p>
                                <Form.Control
                                    type="text"
                                    {...formik.getFieldProps('firstName')}
                                    isInvalid={formik.touched.firstName && formik.errors.firstName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.firstName}
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId="formLastName">
                                <p style={{display: 'inline-flex', margin: '0px'}} className='field-text'>Last Name<p style={{color: 'red', marginLeft: '2px'}}> *</p></p>
                                <Form.Control
                                    type="text"
                                    {...formik.getFieldProps('lastName')}
                                    isInvalid={formik.touched.lastName && formik.errors.lastName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.lastName}
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='email-phone-fields'>
                            <Col sm={6}>
                            <Form.Group controlId="formEmail">
                                <p style={{display: 'inline-flex', margin: '0px'}} className='field-text'>Email<p style={{color: 'red', marginLeft: '2px'}}> *</p></p>
                            <Form.Control
                                type="email"
                                {...formik.getFieldProps('email')}
                                isInvalid={formik.touched.email && formik.errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.email}
                            </Form.Control.Feedback>
                            </Form.Group>
                            </Col>
                            <Col sm={6}>
                            <Form.Group controlId="formPhone">
                                <p style={{display: 'inline-flex', margin: '0px'}} className='field-text'>Phone<p style={{color: 'red', marginLeft: '2px'}}> *</p></p>
                                <PhoneInput
                                    value={formik.values.phone}
                                    defaultCountry='IN'
                                    onChange={(phone) => formik.setFieldValue('phone', phone)}
                                    onBlur={() => formik.setFieldTouched('phone')}
                                />
                                {formik.touched.phone && formik.errors.phone ? (
                                    <div className="error">{formik.errors.phone}</div>
                                ) : null}
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '42px'}}>
                            <p style={{display: 'inline-flex', margin: '0px'}} className='field-text'>Subject<p style={{color: 'red', marginLeft: '2px'}}> *</p></p>
                            <Col>
                                <Form.Check 
                                type="radio" 
                                label="Query" 
                                {...formik.getFieldProps('messageType')}
                                value="Query"
                                id="formHorizontalRadios1" 
                                isInvalid={formik.touched.messageType && formik.errors.messageType}
                                />
                            </Col>
                            <Col>
                                <Form.Check 
                                type="radio" 
                                label="Prayer Request" 
                                {...formik.getFieldProps('messageType')}
                                value="Prayer Request"
                                id="formHorizontalRadios2" 
                                isInvalid={formik.touched.messageType && formik.errors.messageType}
                                />
                            </Col>
                        </Row>

                        <p style={{display: 'inline-flex', margin: '0px'}} className='field-text'>Message<p style={{color: 'red', marginLeft: '2px'}}> *</p></p>
                        <Form.Group controlId="formMessage">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                {...formik.getFieldProps('message')}
                                isInvalid={formik.touched.message && formik.errors.message}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.touched.message && formik.errors.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Button variant="primary" type="submit" className="send-button" disabled={formik.isSubmitting}>
                        SEND MESSAGE
                        </Button>
                    </Form>
                </Col>
            </Row>
        </div>
        {/* Google Maps Iframe */}
        {/* Text for Google Maps iframe */}
        <h2 className="map-text">Find us on Google Map</h2>
        {/* Google Maps iframe container */}
        <div className="map-container">
            <iframe
                className="map-iframe"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7612.024802610835!2d78.48715669197229!3d17.459120533761087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9a6f83c4c095%3A0x565cd1b61e756bd2!2sGrace%20Community%20Baptist%20Church!5e0!3m2!1sen!2sin!4v1700983694401!5m2!1sen!2sin"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    </div>
  );
}

export default Contact;