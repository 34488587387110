import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './HeroSection.css';

const HeroSection = () => {
  return (
    <div className="hero-section" style={{ height: '614px' }}>
      <Container>
        <Row>
          <Col>
            <div className="hero-text">
              <h4>"For by Grace are ye saved through faith; and that not of yourselves: It is the Gift of God" Eph. 2:8</h4>
              <p>Grace Community Baptist Church exists for the glory of God.
                We believe in Christ-centered corporate worship and in living holy lives for
                the service of the Lord. Central to the health of the church is a focus on the
                Bible as the authoritative guide for our lives and prayer as the means of
                communicating with God.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
